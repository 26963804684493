import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react'
import { useConversationContext } from './ConversationProvider'
import { log } from '../utils/log'

const useContextHook = () => {
  const { assistantId } = useConversationContext()
  log('In tokenprovider useContextHook, assistantId:', assistantId)
  const [token, doSetToken] = useState<string>()

  useEffect(() => {
    const local = window.localStorage.getItem('token')
    if (assistantId) {
      doSetToken(local ? (JSON.parse(local)?.[assistantId] as string) || '' : '')
    }
  }, [assistantId])

  const setToken = (val: string) => {
    doSetToken(val)
    const currentTokens = window.localStorage.getItem('token')
    const updatedTokens = currentTokens ? { ...JSON.parse(currentTokens), [assistantId]: val } : { [assistantId]: val }
    window.localStorage.setItem('token', JSON.stringify(updatedTokens))
  }

  return {
    token,
    setToken,
  }
}

type StorageHook = ReturnType<typeof useContextHook>

const TokenContext = createContext<StorageHook>({} as any)

type Props = {
  children?: ReactNode
}

const TokenProvider: FC<Props> = ({ children }) => {
  const props = useContextHook()
  return <TokenContext.Provider value={props}>{children}</TokenContext.Provider>
}

export const useTokenContext = () => useContext(TokenContext)

export default TokenProvider
