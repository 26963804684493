import * as RadixToast from '@radix-ui/react-toast'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import ConversationProvider from '../context/ConversationProvider'
import TokenProvider from '../context/TokenProvider'
import { ToastProvider } from './Toast/ToastProvider'

interface Props {
  children?: ReactNode
}

const ToastViewport = styled(RadixToast.Viewport)`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  padding: var(--toast-padding);
  gap: 16px;
  width: 400px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 10000;
  outline: none;
`

const AppProviders: FC<Props> = ({ children }) => {
  return (
    <ConversationProvider>
      <TokenProvider>
        <RadixToast.Provider swipeDirection="right">
          <ToastProvider>
            {children}
            <ToastViewport />
          </ToastProvider>
        </RadixToast.Provider>
      </TokenProvider>
    </ConversationProvider>
  )
}

export default AppProviders
