import { useQuery } from '@tanstack/react-query'
import { useTokenContext } from '../context/TokenProvider'
import useBaseFetch from '../hooks/useBaseFetch'
import { Assistant } from '../types/assistant'

export const QK_ASSISTANT = '/assistants'

export const useGetAssistantById = (id: string) => {
  const fetch = useBaseFetch()
  const { token } = useTokenContext()

  return useQuery<Assistant>({
    queryKey: [QK_ASSISTANT, id],
    queryFn: () => fetch.get(QK_ASSISTANT),
    enabled: !!id && !!token,
    staleTime: Infinity,
  })
}
