import React, { createContext, FC, ReactNode, useCallback, useContext, useState } from 'react'
import { Toast, ToastProps } from './Toast'

export interface ToastProviderType {
  toast: (title: string, description: ReactNode, actionText?: string, onActionClick?: () => void) => void
  toastSuccess: (title: string, description?: ReactNode, actionText?: string, onActionClick?: () => void) => void
  toastWarning: (title: string, description?: ReactNode, actionText?: string, onActionClick?: () => void) => void
  toastError: (title: string, description?: ReactNode, actionText?: string, onActionClick?: () => void) => void
}

const ToastContext = createContext<ToastProviderType>({
  toast: () => {},
  toastSuccess: () => {},
  toastWarning: () => {},
  toastError: () => {},
})

type Props = {
  closeText?: string
  children: ReactNode
}

export const ToastProvider: FC<Props> = ({ closeText, children }) => {
  const [items, setItems] = useState<ToastProps[]>([])

  const toast = useCallback(
    (title: string, description?: ReactNode, actionText?: string, onActionClick?: () => void) =>
      setItems((prevState) => [
        ...prevState,
        {
          title,
          description,
          actionText,
          onActionClick,
          closeText,
        },
      ]),
    [closeText],
  )

  const toastSuccess = useCallback(
    (title: string, description?: ReactNode, actionText?: string, onActionClick?: () => void) =>
      setItems((prevState) => [
        ...prevState,
        {
          title,
          description,
          actionText,
          onActionClick,
          closeText,
          variant: 'success',
        },
      ]),
    [closeText],
  )

  const toastWarning = useCallback(
    (title: string, description?: ReactNode, actionText?: string, onActionClick?: () => void) =>
      setItems((prevState) => [
        ...prevState,
        {
          title,
          description,
          actionText,
          onActionClick,
          closeText,
          variant: 'warning',
        },
      ]),
    [closeText],
  )

  const toastError = useCallback(
    (title: string, description?: ReactNode, actionText?: string, onActionClick?: () => void) =>
      setItems((prevState) => [
        ...prevState,
        {
          title,
          description,
          actionText,
          onActionClick,
          closeText,
          variant: 'error',
        },
      ]),
    [closeText],
  )

  return (
    <ToastContext.Provider
      value={{
        toast,
        toastSuccess,
        toastWarning,
        toastError,
      }}
    >
      {children}

      {items.map((el, id) => (
        <Toast key={id} {...el} />
      ))}
    </ToastContext.Provider>
  )
}

export const useToast = () => useContext(ToastContext)
