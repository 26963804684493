export const IS_DEV =
  process.env.NODE_ENV === 'development' || String(process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF) !== 'main'

export const FORMAT_DATE_ONLY = 'dd MMM yyyy'
export const FORMAT_DATE_MONTH = 'MMM dd'
export const FORMAT_DATE_API = 'yyyy-MM-dd'
export const FORMAT_TIME_API = 'HH:mm'
export const FORMAT_DATE_WITH_TIME = 'dd MMM yy HH:mm'

export const DEFAULT_PAGE_SIZE = 10
export const EMPTY_ARRAY: any[] = []
