import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    color: ${({ theme }) => theme.colors.darkGray};
    line-height: 1.5;
    font-size: 16px;
    font-family: 'Satoshi', 'Helvetica Neue', sans-serif;
  }
  
  button, input, select {
    line-height: 1.5;
  }
  
  form {
    width: 100%;
  }
  
  ul {
    padding: 0 0 0 16px;
    margin: 0;
  }

  :root {
    --toast-padding: 25px;
  }
`

export default GlobalStyle
