import 'modern-normalize/modern-normalize.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import '../styles/fonts.css'
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import App from 'next/app'
import type { AppProps } from 'next/app'
import { useState } from 'react'
import { ThemeProvider } from 'styled-components'
import AppProviders from '../components/AppProviders'
import { defaultTheme } from '../styles/DefaultTheme'
import GlobalStyle from '../styles/GlobalStyle'

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 60000,
            retry: 1,
          },
        },
      }),
  )

  return (
    <ThemeProvider theme={defaultTheme}>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <GlobalStyle />
          <AppProviders>
            <Component {...pageProps} />
          </AppProviders>
        </HydrationBoundary>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

MyApp.getInitialProps = async (ctx: any) => {
  const appProps = await App.getInitialProps(ctx)

  return {
    ...appProps,
  }
}

export default MyApp
