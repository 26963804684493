import { DefaultTheme } from 'styled-components'

export const defaultTheme: DefaultTheme = {
  colors: {
    white: '#fff',
    black: '#000',
    offWhite: '#f4fcff',
    darkGray: '#1A1E1F',
    primaryBlue: '#07155F',
    primaryBlueHover: '#030D41',
    slate50: '#F8FAFC',
    slate100: '#F1F5F9',
    slate200: '#E2E8F0',
    slate300: '#CBD5E1',
    slate500: '#64748B',
    gray50: '#F9FAFB',
    gray100: '#F3F4F6',
    gray200: '#EAECF0',
    gray300: '#D0D5DD',
    gray400: '#9CA3AF',
    gray500: '#6B7280',
    gray700: '#344054',
    gray800: '#1F2937',
    teal100: '#CCFBF1',
    teal500: '#14B8A6',
    turquoise1: '#9CF9FF',
    turquoise2: '#CDFCFF',
    red50: '#FEF3F2',
    red200: '#FECDCA',
    red300: '#FDA29B',
    red600: '#DC2626',
    red700: '#B91C1C',
    blue50: '#EFF6FF',
    blue100: '#DBEAFE',
    blue500: '#3B82F6',
    blue600: '#2563EB',
    sky50: '#F0F9FF',
    sky100: '#e0f2fe',
    yellow50: '#FEFCE8',
    yellow400: '#FACC15',
    yellow700: '#A16207',
    teal50: '#F0FDFA',
    teal400: '#2DD4BF',
    green700: '#15803D',
  },
  transition: 'all .25s ease-out',
  boxShadow: '0px 1px 2px 0px #1018280D',
}
