import * as Sentry from '@sentry/nextjs'
import { IS_DEV } from './const'

export const log = (...args: any[]) => {
  if (IS_DEV) {
    console.log(...args)
  } else {
    Sentry.addBreadcrumb({
      category: 'log',
      message: args.join(' '),
      level: 'info',
    })
  }
}
