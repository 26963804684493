import * as RadixToast from '@radix-ui/react-toast'
import React, { ComponentProps, FC, ReactNode } from 'react'
import styled, { css, keyframes } from 'styled-components'
import Button from '../Button/Button'

type Variant = 'default' | 'success' | 'warning' | 'error'

export interface ToastProps extends ComponentProps<typeof RadixToast.Root> {
  title: string
  description?: ReactNode
  variant?: Variant
  actionText?: string
  onActionClick?: () => void
  closeText?: string
}

const hide = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`

const slideIn = keyframes`
  from {
    transform: translateX(calc(100% + var(--toast-padding)));
  }

  to {
    transform: translateX(0);
  }
`

const swipeOut = keyframes`
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }

  to {
    transform: translateX(calc(100% + var(--toast-padding)));
  }
`

const Root = styled(RadixToast.Root)<{ $variant: Variant }>`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  padding: 16px;
  align-items: center;
  position: relative;
  word-break: break-word;

  ${({ theme, $variant }) =>
    $variant === 'success' &&
    css`
      background: ${theme.colors.teal50};
      color: ${theme.colors.green700};
    `}

  ${({ theme, $variant }) =>
    $variant === 'warning' &&
    css`
      background: ${theme.colors.blue50};
      color: ${theme.colors.gray500};
    `}

  ${({ theme, $variant }) =>
    $variant === 'error' &&
    css`
      background: ${theme.colors.yellow50};
      color: ${theme.colors.yellow700};
    `}

  @media (prefers-reduced-motion: no-preference) {
    &[data-state='open'] {
      animation: ${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    }

    &[data-state='closed'] {
      animation: ${hide} 100ms ease-in;
    }

    &[data-swipe='move'] {
      transform: translateX(var(--radix-toast-swipe-move-x));
    }

    &[data-swipe='cancel'] {
      transform: translateX(0);
      transition: transform 200ms ease-out;
    }

    &[data-swipe='end'] {
      animation: ${swipeOut} 100ms ease-out;
    }
  }
`

const Inner = styled.div`
  display: flex;
  gap: 16px;
`

const Content = styled.div`
  flex: 1;
`

const Title = styled(RadixToast.Title)`
  font-weight: 600;
`

const Icon = styled.div<{ $variant: Variant }>`
  ${({ theme, $variant }) =>
    $variant === 'success' &&
    css`
      color: ${theme.colors.teal400};
    `}

  ${({ theme, $variant }) =>
    $variant === 'warning' &&
    css`
      color: ${theme.colors.blue600};
    `}

  ${({ theme, $variant }) =>
    $variant === 'error' &&
    css`
      color: ${theme.colors.yellow400};
    `}
`

const Description = styled(RadixToast.Description)`
  flex: 1;
  margin-top: 5px;
  line-height: 24px;
`

const Action = styled(RadixToast.Action)`
  margin-top: 8px;
`

const Close = styled(RadixToast.Close)`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;
`

export const Toast: FC<ToastProps> = ({
  title,
  description,
  variant = 'default',
  actionText,
  onActionClick,
  closeText = 'Close',
  ref,
  ...rest
}) => (
  <Root {...rest} $variant={variant}>
    <Inner>
      {variant === 'success' && (
        <Icon $variant={variant}>
          <i className="bi-check-circle-fill" />
        </Icon>
      )}

      {variant === 'warning' && (
        <Icon $variant={variant}>
          <i className="bi-info-circle-fill" />
        </Icon>
      )}

      {variant === 'error' && (
        <Icon $variant={variant}>
          <i className="bi-exclamation-triangle-fill" />
        </Icon>
      )}

      <Content>
        <Title>{title}</Title>

        {description && <Description>{description}</Description>}

        {actionText && (
          <Action asChild altText={actionText}>
            <Button onClick={onActionClick}>{actionText}</Button>
          </Action>
        )}
      </Content>

      <Close aria-label={closeText}>
        <i className="bi-x-lg" />
      </Close>
    </Inner>
  </Root>
)
