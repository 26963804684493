import { AnchorHTMLAttributes, DetailedHTMLProps, forwardRef, ReactElement } from 'react'
import styled, { css } from 'styled-components'
import { useGetAssistantById } from '../../api/assistant'
import { useConversationContext } from '../../context/ConversationProvider'
import Spinner from '../Placeholder/Spinner'

type Variant = 'primary' | 'secondary' | 'outline' | 'link' | 'danger-outline'
type Size = 'sm' | 'md' | 'lg'

interface Props extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  variant?: Variant
  size?: Size
  fullWidth?: boolean
  disabled?: boolean
  loading?: boolean
  leftIcon?: ReactElement
  rightIcon?: ReactElement
  component?: 'button' | 'a'
  openNewTab?: boolean
  className?: string
}

const Root = styled.button<{
  $variant: Variant
  $size: Size
  $bg?: string
  $fullWidth?: boolean
  disabled?: boolean
}>`
  border: 1px solid transparent;
  text-align: center;
  user-select: none;
  border-radius: 6px;
  transition: ${({ theme }) => theme.transition};
  font-weight: 500;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  vertical-align: middle;

  &:active {
    box-shadow: ${({ theme }) => theme.boxShadow};
  }

  &:focus {
    outline-offset: 2px;
  }

  ${({ $size }) =>
    $size === 'sm' &&
    css`
      font-size: 14px;
      padding: 8px 16px;
    `}

  ${({ $size }) =>
    $size === 'md' &&
    css`
      font-size: 16px;
      padding: 11px 20px;
    `}

  ${({ $size }) =>
    $size === 'lg' &&
    css`
      font-size: 18px;
      padding: 13px 20px;
    `}

  ${({ $variant, $bg }) =>
    $variant === 'primary' &&
    css`
      background: ${({ theme }) => $bg || theme.colors.primaryBlue};
      border-color: ${({ theme }) => $bg || theme.colors.primaryBlue};
      color: ${({ theme }) => theme.colors.white};

      &:hover {
        background: ${({ theme }) => $bg || theme.colors.primaryBlueHover};
      }

      &:focus {
        outline: ${({ theme }) => $bg || theme.colors.primaryBlueHover} solid 2px;
      }

      &[disabled] {
        background: ${({ theme }) => theme.colors.slate200};
        border-color: ${({ theme }) => theme.colors.slate200};
      }
    `}

  ${({ $variant }) =>
    $variant === 'secondary' &&
    css`
      background: ${({ theme }) => theme.colors.turquoise1};
      border-color: ${({ theme }) => theme.colors.turquoise1};
      color: ${({ theme }) => theme.colors.primaryBlueHover};

      &:hover {
        background: ${({ theme }) => theme.colors.slate100};
        border-color: ${({ theme }) => theme.colors.slate100};
      }

      &:focus {
        outline: ${({ theme }) => theme.colors.slate100} solid 2px;
      }

      &[disabled] {
        background: ${({ theme }) => theme.colors.turquoise2};
        border-color: ${({ theme }) => theme.colors.turquoise2};
        color: ${({ theme }) => theme.colors.slate300};
      }
    `}

  ${({ $variant }) =>
    $variant === 'outline' &&
    css`
      background: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.gray300};
      color: ${({ theme }) => theme.colors.gray700};

      &:hover {
        background: ${({ theme }) => theme.colors.gray100};
      }

      &:focus {
        outline: ${({ theme }) => theme.colors.primaryBlue} solid 2px;
      }

      &[disabled] {
        background: ${({ theme }) => theme.colors.white};
        border-color: ${({ theme }) => theme.colors.gray200};
        color: ${({ theme }) => theme.colors.gray300};
      }
    `}

  ${({ $variant }) =>
    $variant === 'link' &&
    css`
      background: transparent;
      border-color: transparent;
      color: ${({ theme }) => theme.colors.gray700};

      &:hover {
        background: ${({ theme }) => theme.colors.gray100};
      }

      &:focus {
        outline: ${({ theme }) => theme.colors.gray100} solid 2px;
      }

      &[disabled] {
        background: ${({ theme }) => theme.colors.gray200};
        border-color: ${({ theme }) => theme.colors.gray200};
        color: ${({ theme }) => theme.colors.gray300};
      }
    `}

  ${({ $variant }) =>
    $variant === 'danger-outline' &&
    css`
      background: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.red300};
      color: ${({ theme }) => theme.colors.red700};

      &:hover {
        background: ${({ theme }) => theme.colors.red50};
      }

      &:focus {
        outline: ${({ theme }) => theme.colors.red50} solid 2px;
      }

      &[disabled] {
        background: ${({ theme }) => theme.colors.red200};
        border-color: ${({ theme }) => theme.colors.red200};
        color: ${({ theme }) => theme.colors.red300};
      }
    `}

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `};

  &[disabled] {
    pointer-events: none;
  }
`

const Icon = styled.div``

const LeftIcon = styled(Icon)`
  margin-inline-end: 10px;
`

const RightIcon = styled(Icon)`
  margin-inline-start: 10px;
`

const ProgressRoot = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  margin: -1px;
  color: white;
  border-radius: 6px;
`

const Button = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      variant = 'primary',
      size = 'md',
      fullWidth,
      disabled,
      loading,
      leftIcon,
      rightIcon,
      component = 'button',
      openNewTab,
      className,
      children,
      ...rest
    },
    ref,
  ) => {
    const { assistantId } = useConversationContext()
    const { data } = useGetAssistantById(assistantId)

    return (
      <Root
        $variant={variant}
        $size={size}
        $fullWidth={fullWidth}
        disabled={disabled || loading}
        as={component}
        className={className}
        type={component === 'button' ? 'button' : undefined}
        target={openNewTab ? '_blank' : undefined}
        rel={openNewTab ? 'noreferrer noopener' : undefined}
        $bg={data?.brand_color}
        {...rest}
        ref={ref}
      >
        {leftIcon && <LeftIcon>{leftIcon}</LeftIcon>}

        {children}

        {rightIcon && <RightIcon>{rightIcon}</RightIcon>}

        {loading && (
          <ProgressRoot>
            <Spinner />
          </ProgressRoot>
        )}
      </Root>
    )
  },
)

Button.displayName = 'Button'

export default Button
