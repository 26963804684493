import fetch from 'cross-fetch'
import { omit } from 'lodash'
import { useRouter } from 'next/router'
import queryString from 'query-string'
import { useCallback } from 'react'
import { useConversationContext } from '../context/ConversationProvider'
import { useTokenContext } from '../context/TokenProvider'
import { log } from '../utils/log'

const API_URL = `${process.env.NEXT_PUBLIC_API_URL}`

export const parseError = (status: number, body: any): string => {
  return {
    ...body,
    status,
    message: body.message || body.detail?.[0]?.msg || 'Error',
  }
}

export const getHeaders = (
  token: string | null | undefined,
  language?: string | null,
  assistantId?: string | null,
): any => {
  const headers: any = {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    'Accept-language': language || 'en-US',
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  if (assistantId) {
    headers['X-assistant-id'] = assistantId
  }

  return headers
}

const buildQuery = (params: any) => queryString.stringify(params)

export const baseFetchGet = async (
  token: string | null | undefined,
  language: string | null | undefined,
  url: string,
  params?: any,
  assistantId?: string | null,
) => {
  const headers = omit(getHeaders(token, language, assistantId), ['Content-Type'])

  const res = await fetch(`${API_URL}${url}?${buildQuery(params)}`, {
    headers,
    method: 'GET',
  })

  if (!res.ok) {
    log('GET failed:', res)
    const body = await res.json()
    throw parseError(res.status, body)
  }

  return res.json()
}

export const baseFetchPost = async (
  token: string | null | undefined,
  language: string | null | undefined,
  url: string,
  data?: any,
  params?: any,
  assistantId?: string | null,
) => {
  const headers = getHeaders(token, language, assistantId)

  const res = await fetch(`${API_URL}${url}?${buildQuery(params)}`, {
    headers,
    body: JSON.stringify(data),
    method: 'POST',
  })

  if (!res.ok) {
    const body = await res.json()
    throw parseError(res.status, body)
  }

  return res.json()
}

export const baseFetchPut = async (
  token: string | null | undefined,
  language: string | null | undefined,
  url: string,
  data?: any,
  params?: any,
  assistantId?: string | null,
) => {
  const headers = getHeaders(token, language, assistantId)

  const res = await fetch(`${API_URL}${url}?${buildQuery(params)}`, {
    headers,
    body: JSON.stringify(data),
    method: 'PUT',
  })

  if (!res.ok) {
    const body = await res.json()
    throw parseError(res.status, body)
  }

  return res.json()
}

export const baseFetchPatch = async (
  token: string | null | undefined,
  language: string | null | undefined,
  url: string,
  data?: any,
  params?: any,
  assistantId?: string | null,
) => {
  const headers = getHeaders(token, language, assistantId)

  const res = await fetch(`${API_URL}${url}?${buildQuery(params)}`, {
    headers,
    body: JSON.stringify(data),
    method: 'PATCH',
  })

  if (!res.ok) {
    const body = await res.json()
    throw parseError(res.status, body)
  }

  return res.json()
}

export const baseFetchRemove = async (
  token: string | null | undefined,
  language: string | null | undefined,
  url: string,
  params?: any,
  assistantId?: string | null,
) => {
  const headers = getHeaders(token, language, assistantId)

  const res = await fetch(`${API_URL}${url}?${buildQuery(params)}`, {
    headers,
    method: 'DELETE',
  })

  if (!res.ok) {
    const body = await res.json()
    throw parseError(res.status, body)
  }

  return res.json()
}

export const baseFetchUpload = async (
  token: string | null | undefined,
  language: string | null | undefined,
  url: string,
  data: any,
  params?: any,
  assistantId?: string | null,
) => {
  const formData = new FormData()
  const headers = omit(getHeaders(token, language, assistantId), ['Content-Type'])

  formData.append('file', data)

  const res = await fetch(`${API_URL}${url}?${buildQuery(params)}`, {
    headers,
    body: formData,
    method: 'POST',
  })

  if (!res.ok) {
    const body = await res.json()
    throw parseError(res.status, body)
  }

  return res.json()
}

export type FetchContextType = {
  get: <TResult = any, TParams = any>(url: string, params?: TParams, assistantId?: string | null) => Promise<TResult>
  post: <TResult = any, TData = any, TParams = any>(
    url: string,
    data?: TData,
    params?: TParams,
    assistantId?: string | null,
  ) => Promise<TResult>
  put: <TResult = any, TData = any, TParams = any>(
    url: string,
    data?: TData,
    params?: TParams,
    assistantId?: string | null,
  ) => Promise<TResult>
  patch: <TResult = any, TData = any, TParams = any>(
    url: string,
    data?: TData,
    params?: TParams,
    assistantId?: string | null,
  ) => Promise<TResult>
  remove: <TResult = any, TParams = any>(url: string, params?: TParams, assistantId?: string | null) => Promise<TResult>
  upload: <TResult = any, TData = any, TParams = any>(
    url: string,
    data?: TData,
    params?: TParams,
    assistantId?: string | null,
  ) => Promise<TResult>
}

const useBaseFetch = (): FetchContextType => {
  const { token } = useTokenContext()
  const { assistantId } = useConversationContext()
  log('In useBaseFetch, assistantId:', assistantId, ' - getting token context')
  const router = useRouter()

  const get = useCallback(
    async (url: string, params?: any) => baseFetchGet(token, router.locale, url, params, assistantId),
    [router.locale, token, assistantId],
  )

  const post = useCallback(
    async (url: string, data?: any, params?: any) =>
      baseFetchPost(token, router.locale, url, data, params, assistantId),
    [router, token, assistantId],
  )

  const put = useCallback(
    async (url: string, data?: any, params?: any) => baseFetchPut(token, router.locale, url, data, params, assistantId),
    [router, token, assistantId],
  )

  const patch = useCallback(
    async (url: string, data?: any, params?: any) =>
      baseFetchPatch(token, router.locale, url, data, params, assistantId),
    [router, token, assistantId],
  )

  const remove = useCallback(
    async (url: string, params?: any) => baseFetchRemove(token, router.locale, url, params, assistantId),
    [router, token, assistantId],
  )

  const upload = useCallback(
    async (url: string, data: any, params?: any) =>
      baseFetchUpload(token, router.locale, url, data, params, assistantId),
    [router, token, assistantId],
  )

  return {
    get,
    post,
    put,
    patch,
    remove,
    upload,
  }
}

export default useBaseFetch
